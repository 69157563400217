module.exports = [{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-mui-emotion/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@1_fxxrbfwzew2wqw3mdjz5djzh74/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-195430828-1","AW-381586643"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3_55iyfhdb77m66fvka37fxoppna/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"navitas.at","short_name":"navitas.at","description":"Massagen / Wimpern / Lashes und Kosmetik in Bleiburg","lang":"de","start_url":"/","background_color":"#19857b","theme_color":"#19857b","display":"standalone","icon":"src/images/favicon-128.png","crossOrigin":"anonymous","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"cfe049fcaa8efb41ed31d03f3d62a3f7"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-gatsby-cloud@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@_7uqb6zp4w2yqcrdi4youorkej4/node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__bcqnfvlqr4oju7g57pailpsiky/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
