import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import '@fontsource/abeezee';

// A custom theme for this app
const defaultTheme = createTheme({
  palette: {
    primary: {
      main: 'rgb(25, 133, 123)',
    },
    secondary: {
      main: 'rgb(25, 133, 123)',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: 'ABeeZee',
    fontSize: 16,
  },
});

export default defaultTheme;
